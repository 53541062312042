import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { l } from '../Languages';

const Search = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const [keyword, setSearch] = useState("");
    const [displayKeyword, setDisplayKeyword] = useState("");
    const [results, setResult] = useState([]);
    const [bookData, setBookData] = useState(null);
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    useEffect(() => {
        setBookData(JSON.parse(localStorage.getItem('bookData')));
    }, []);

    const handleSearch = async (event) => {
        event.preventDefault();

        try {
            axios.defaults.headers.bible = localStorage.getItem('selectedBible');
            axios.defaults.headers.language = localStorage.getItem('selectedLanguage');
            const response = await axios.get(`${API_URL}/search/${keyword}`);
            // handle response here
            console.log(response.data);
            setResult(response.data.verses);
            setDisplayKeyword(keyword);
        } catch (error) {
            console.error('An error occurred during search', error);
            // handle error here
        }
    };

    return  <div className="main">

                <section className="page-title py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{l[s].search}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="single-post mw-sm mx-auto">
                                <div className="entry">
                                    <div className="entry-content">
                                        <br /><br />
                                        <form className="row" onSubmit={handleSearch}>
                                            <div className="col-7">
                                                <input type="text" id="keyword" value={keyword} onChange={(e) => setSearch(e.target.value)} className="form-control form-control-lg" required />
                                            </div>
                                            <div className="col-3">
                                                <button type="submit" className="button btn-lg">{l[s].search}</button>
                                            </div>
                                        </form>
                                        <div>
                                            { results.length === 0 && displayKeyword.length === 0 && (`${l[s].searchTip}`)}
                                            { results.length > 0 && results.length < 500 && (`"${displayKeyword}" ${l[s].searchResults1} ${results.length} ${l[s].searchResults2}`)}
                                            { results.length >= 500 && (`"${displayKeyword}" ${l[s].searchOverResultsTip}`)}
                                            { results.length === 0 && displayKeyword !== "" && (`${l[s].searchNoResult1} "${displayKeyword}" ${l[s].searchNoResult2}`)}
                                            < hr/>
                                            {Array.isArray(results) && results.map((item, index) => {
                                              // Split the current item into an array using the keyword
                                              const parts = item.content.split(new RegExp(`(${displayKeyword})`, 'gi'));
                                              return (
                                                <p key={index}>
                                                    <Link to={`/read/${item.book_num + 1}/${item.chapter_num + 1}/${item.verse_num + 1}`}>
                                                        {bookData[item.book_num].title}
                                                        {item.chapter_num + 1}:{item.verse_num + 1} 
                                                        {parts.map((part, index) =>
                                                            part.toLowerCase() === displayKeyword.toLowerCase() ? (<span key={index} style={{ fontWeight: '700', }}>{part}</span>) : ( part ))}
                                                    </Link>
                                                </p>);
                                            })}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>



            </div>;
};

export default Search;