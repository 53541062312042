import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { l } from '../Languages';

const Terms = () => {
    const navigate = useNavigate();
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    return  <div className="main">

                <section className="page-title page-title-parallax parallax include-header py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            {/* ================= Terms of Service ================= */}
                            <div className="col-md-8">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{l[s].termsOfService}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="single-post mw-sm mx-auto">
                                <div className="entry">
                                    <div className="entry-content">
                                        <p>Website Name: Biblearn.com</p>
                                        <p>Effective date: 6/26/2023</p>

                                        <ol>
                                            <li>
                                                <h4>Introduction</h4>
                                                
                                                <p>Welcome to Biblearn ("Company", "we", "our", "us")! These Terms of Service ("Terms", "Terms of Service") govern your use of our website located at [website URL] (together or individually, the "Service") operated by Biblearn.</p>

                                                <p>By accessing or using the Service you agree to be bound by these Terms. If you disagree with any part of the terms, you may not access the Service.</p>
                                            </li>

                                            <li>
                                                <h4>Accounts</h4>
                                            
                                                <p>When you create an account with us, you guarantee that the information you provide us is accurate, complete, and current at all times. Inaccurate, incomplete, or obsolete information may result in the immediate termination of your account on the Service.</p>
                                            </li>

                                            <li>
                                                <h4>Intellectual Property</h4>
                                                
                                                <p>The Service and its original content, features, and functionality are and will remain the exclusive property of Biblearn and its licensors.</p>
                                            </li>

                                            <li>
                                                <h4>Links to Other Web Sites</h4>
                                            
                                                <p>Our Service may contain links to third-party web sites or services that are not owned or controlled by Biblearn. Biblearn has no control over and assumes no responsibility for the content, privacy policies, or practices of any third-party web sites or services.</p>
                                            </li>

                                            <li>
                                                <h4>Termination</h4>
                                            
                                                <p>We may terminate or suspend your account and bar access to the Service immediately, without prior notice or liability, under our sole discretion, for any reason whatsoever and without limitation, including but not limited to a breach of the Terms.</p>
                                            </li>

                                            <li>
                                                <h4>Governing Law</h4>
                                            
                                                <p>These Terms shall be governed and construed in accordance with the laws of [Location], without regard to its conflict of law provisions.</p>
                                            </li>

                                            <li>
                                                <h4>Changes</h4>
                                            
                                                <p>We reserve the right, at our sole discretion, to modify or replace these Terms at any time.</p>
                                            </li> 

                                            <li>
                                                <h4>Chatbot Conversations</h4>

                                                <p>User acknowledges and agrees that the chatbot conversation feature on our website is powered by OpenAI's GPT (Generative Pretrained Transformer). While we strive to provide accurate information, due to the automated nature of this feature, we cannot guarantee the accuracy, reliability or completeness of any information provided through the chatbot. User agrees to exercise discretion in interpreting and acting upon the information provided by the chatbot. Biblearn will not be held liable for any decisions or actions taken by the user based on the information provided by the chatbot. User agrees that they will not hold Biblearn responsible for any form of damages or pursue any legal action against Biblearn resulting from the use of or reliance on any information provided by the chatbot.</p>
                                                </li>
                                        </ol>



                                        <p>User hereby acknowledges this important information and agrees to all terms of service.</p>

                                        <h4>Contact Us</h4>

                                        <p>If you have any questions about these Terms, please <Link to="/Contact">contact us</Link>.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>;
};

export default Terms;