import { l } from '../Languages';

const Faq = () => {
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    return  <div className="main">

                <section className="page-title page-title-parallax parallax include-header py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            {/* ================= FAQ ================= */}
                            <div className="col-md-8">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{l[s].faq}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="single-post mw-sm mx-auto">
                                <div className="entry">
                                    <div className="entry-content">
                                        <p>{l[s].contentComingSoon}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>;
};

export default Faq;