import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { l } from '../Languages';

const Books = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [bookData, setBookData] = useState({});
    const [selectedBible, setSelectedBible] = useState(localStorage.getItem('selectedBible'));
    const [selectedLanguage, setSelectedLanguage] = useState(localStorage.getItem('selectedLanguage'));
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    useEffect(() => {
        if (!selectedBible) fetchData();
        setBookData(JSON.parse(localStorage.getItem('bookData')));
    }, []);

    useEffect(() => {
        console.log(localStorage.getItem('selectedBible'));
    }, []);

    if (!bookData) {
        return 'Loading...'; // this will be displayed before the data has been fetched
    }

    const fetchData = async () => {
        console.log(localStorage.getItem('selectedBible'));
        let headerBible = localStorage.getItem('selectedBible');
        let headerLanguage = localStorage.getItem('selectedLanguage');
        if (headerBible === null) headerBible = "enUsKjv";
        if (headerLanguage === null) headerLanguage = "enUs";
        axios.defaults.headers.bible = headerBible;
        axios.defaults.headers.language = headerLanguage;
        const response = await axios.get(`${API_URL}/books`);
        console.log(response);
        setBookData(response.data.books);
        localStorage.setItem('bookData', JSON.stringify(response.data.books));
    }

    const handleBookChange = (e) => {
        console.log(`e.target.value: ${e.target.value}`);
        setSelectedBible(e.target.value);
        console.log(`selectedBible: ${e.target.value}`);
        localStorage.setItem('selectedBible', e.target.value);
        let selectedLang = e.target.value.substring(0, 4);
        console.log(`selectedLang: ${selectedLang}`);
        localStorage.setItem('selectedLanguage', selectedLang);
        fetchData();
    }

    return  <div className="main">

                <section className="page-title py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{ l[s].books }</h1>
                                        <select value={selectedBible} onChange={handleBookChange} className="form-select">
                                            <option value="enUsKjv" >King James Version</option>
                                            <option value="zhTw" >中文和合本繁體聖經</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap">
                        <div className="container">
                            <div className="row col-mb-50">
                                <div className="col-md-2"></div>
                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header">{l[s].oldTestaments}</div>
                                        <div className="card-body">
                                            <div className="w-100 mb-5">
                                                {Array.isArray(bookData) && bookData.map((item, index) => (
                                                    item.section === 0 ? (
                                                        <div key={index}>
                                                            <Link to={`/read/${index + 1}/1`} className="social-icon">
                                                                {item.short_title}
                                                            </Link>
                                                        </div>
                                                    ) : null
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="col-md-4">
                                    <div className="card">
                                        <div className="card-header">{l[s].newTestaments}</div>
                                        <div className="card-body">
                                            <div className="w-100 mb-5">
                                                {Array.isArray(bookData) && bookData.map((item, index) => (
                                                    item.section === 1 ? (
                                                        <div key={index}>
                                                            <Link to={`/read/${index + 1}/1`} className="social-icon">
                                                                {item.short_title}
                                                            </Link>
                                                        </div>
                                                    ) : null
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-2"></div>


                            </div>
                        </div>
                    </div>
                </section>

            </div>;
};

export default Books;