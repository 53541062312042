import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { l } from '../Languages';

const SignIn = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [error, setError] = useState("");
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    const handleSubmit = (e) => {
        e.preventDefault();
    
        const loginData = { username, password };

        const handleLogin = async () => {
            setError("");
            try {
                const response = await axios.post(`${API_URL}/login`, loginData);
                console.log(response.data);
                localStorage.setItem('jwtToken', JSON.stringify(response.data));
                localStorage.setItem('user', JSON.stringify(response.data.user));
                console.log(response.data);
                navigate('/');
            } catch (error) {
                console.error('An error occurred during signup', error);
                if (error.response.status === 401) {
                    setError(error.response.data);
                }
            }
        }

        handleLogin();


        // fetch(`${API_URL}/login`, {
        //     method: 'POST',
        //     headers: { 'Content-Type': 'application/json' },
        //     body: JSON.stringify(loginData)
        // })
        // .then(response => response.json())
        // .then(data => {
        //     localStorage.setItem('jwtToken', JSON.stringify(data));
        //     localStorage.setItem('user', JSON.stringify(data.user));
        //     console.log(data);
        //     navigate('/');
        // })
        // .catch(error => {
        //     console.error('Error:', JSON.stringify(error));
        //     // if (error.response.status === 401) {
        //     //     setError(error.response.data);
        //     // }

        // });
    };

    return  <div className="main">
                <section className="page-title py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            <div className="col-md-8">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{l[s].signIn}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="row col-mb-50">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <form className="" onSubmit={handleSubmit}>
                                            <fieldset>
                                                <label id="username">{l[s].username}</label>
                                                <input type="text" id="username" value={username} className="form-control" onChange={e => setUsername(e.target.value)} placeholder={l[s].username} required />
                                                <label id="Password">{l[s].password}</label>
                                                <input type="password" id="password" value={password} className="form-control" onChange={(e) => setPassword(e.target.value)} placeholder={l[s].password} required />
                                                    <button type="submit" className="btn btn-primary mt-3">{l[s].signIn}</button>
                                            </fieldset>
                                        </form>
                                        {(<p style={{ color: 'blue', fontWeight: 300}}>Test username: testuser, <br />password: testuser</p>)}
                                        {(<p style={{ color: '#FF0000', fontWeight: 500}}>{error}</p>)}
                                        {l[s].dontHaveAnAccount} <Link to="/contact">{l[s].contactUs}</Link>.
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>;
};

export default SignIn;
