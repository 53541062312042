export const l = {
                enUs: {
                    langCode: "enUs",
                    logoName: "Biblearn",
                    home: "Home",
                    homeCopy: "<p></p><p>👋 Biblearn <b>(&ldquo;Bible&rdquo; + &ldquo;Learn&rdquo;)</b> is a bible reader and with AI answers to your biblical questions (powered by ChatGPT). The goal is to assist users in not only understanding the scriptures but also unraveling the complexities and interconnections within this book.</p><p>Biblearn currently support English King James Version & Chinese Traditional holy bible, other languages will be added later.</p><p>The chat function is currently in closed beta testing, once it is ready, it will be opened gradually to more users.</p>",
                    read: "Read",
                    chapter: "Chapter",
                    chapterShort: "Ch",
                    verse: "Verse",
                    verseShort: "Ver.",
                    books: "Books",
                    fonts: "Fonts",
                    search: "Search",
                    readNow: "Read Now",
                    slogan1: "Make your bible reading enlightening",
                    ask: "Ask",
                    chatTips: ["You need the privillege and sign in to use the chat service.","To use the chat, please select the verses on the left, choose a question from the dropdown list and hit the send button.","You can also ask your own questions just by typing in the text box below.","We kindly request that you only ask bible-related or Christianity-based questions.","This tool is designed as a Bible reading assistant; it's not a human or an individual with biblical authority. Please exercise discretion when interpreting the answers.","The AI-generated responses may contain errors. If you are not completely sure about an answer, we recommend consulting with a pastor."],
                    chatQuestions: [" Explain these verses","What is the background?"," Who are these characters"," Explain the place and time of the verses"," Why do author write this passage?"," Why did the characters say or acted in such a way?"," Who is the author?"," What happened in this passage?"," What is the teaching here?"],
                    tipTitle: "Tips",
                    oldTestaments: "Old Testaments",
                    newTestaments: "New Testaments",
                    searchTip: "Please enter the keywords to search the matching verses.",
                    searchResults1: "keyword has",
                    searchResults2: "results, click the result to go to the verse.",
                    searchOverResultsTip: "There are over 500 results (limted to 500)，please refine your keywords. Click the result to go to the verse.",
                    searchNoResult1: "There was 0 results for ",
                    searchNoResult2: "Please enter the keywords to search the matching verses.",
                    signIn: "Sign In",
                    signUp: "Sign Up",
                    signOut: "Sign Out",
                    username: "Username",
                    password: "Password",
                    dontHaveAnAccount: "Don't have an account?",
                    contentComingSoon: "Content coming soon...",
                    profile: "Profile",
                    settings: "Settings",
                    general: "General",
                    helpAndSupport: "Help & Support",
                    helpAndSupportCopy: "",
                    faq: "FAQ",
                    faqCopy: "",
                    aboutUs: "About Us",
                    about: "About",
                    aboutCopy: "<p>What is \"Biblearn\"? It sound like a quirky mash-up, but it's a playful blend of \"Bible\" and \"Learn\", it's an attempt to make scripture learning engaging.</p><p>Why \"Biblearn\"? As a software engineer, a Christian, and a bible reader, I often find myself yearning to unravel the truths embedded within God's words. I frequently have queries, reflective thoughts, and a desire to dive deeper into the profound teachings of the Bible. This led me to conceive this platform, purpose-built for individuals like me who are on a quest for knowledge and understanding. It's to provide answers, stimulate thought, and aid in your spiritual exploration of the Bible's enriching scriptures.</p><p>While the power of ChatGPT brings value in providing answers, the API operating cost is high, the subscription model may be implemented in the future. I understand that everyone's needs and financial circumstances vary, so I encourage you to manage your subscription as you see fit. Your support in any form is greatly appreciated and directly contributes to the ongoing availability of this service.</p><p>It's also important to note that while the tool strive for accuracy, the answers provided are generated by ChatGPT so it may not always be perfectly accurate or factually correct. I urge you to utilize the tool as a guide and not as the final authority on biblical knowledge. For more complex or deep-rooted questions, I recommend seeking help from pastors, clergies, or other trusted spiritual mentors.</p><p>It currently support Chinese Traditional and English KJV holy bibles, other languages will be added gradually.</p><p>I hope you find the tool helpful and enjoy using it! Let's embark on this enlightening journey together.</p>",
                    termsAndPrivacy: "Terms & Privacy",
                    termsOfService: "Terms of Service",
                    privacyPolicy: "Privacy Policy",
                    contactUs: "Contact Us",
                    copyright: "All Rights Reserved by",
                    pageNotFound: "The page is not found. Return to"
                },

                zhTw: {
                    langCode: "zhTw",
                    logoName: "Biblearn",
                    home: "首頁",
                    homeCopy: "<p></p><p>👋 Biblearn <b>(“聖經”+“學習”)</b> 是一款聖經閱讀器，可通過 AI 解答您的聖經問題（答案由 ChatGPT 提供）。 目標是幫助用戶不僅理解經文，而且解開本書中的字義和信息關聯性。</p><p>Biblearn 目前支持英文KJV聖經和中文和合本繁體聖經，稍後將添加其他語言。 </p><p>聊天功能目前處於內測階段，待完善後將逐步向更多用戶開放。</p>",
                    read: "閱讀",
                    chapter: "章",
                    chapterShort: "章",
                    verse: "節",
                    verseShort: "節",
                    books: "書本",
                    fonts: "字體",
                    search: "搜尋",
                    readNow: "立即閱讀",
                    slogan1: "讓你的聖經閱讀充滿啟發",
                    ask: "發問",
                    chatTips: ["您需要權限並登錄才能使用聊天服務。","使用聊天功能時請選擇左側的經文，從下拉列表中選擇一個問題，然後單擊發送按鈕。","您也可以發問自己的問題，只需在下面的文本框中輸入您自己的問題即可。","懇請您只提出與聖經相關或基於基督教的問題。","此工具被設計為聖經閱讀助手；它不是人類或 具有聖經權威的人回答問題，請謹慎解讀答案。","人工智能生成的答案可能有錯誤。如果您不完全確定答案，我們建議諮詢牧師。"],
                    chatQuestions: ["請解釋這些經文","這段經文的背景是什麼？","這段經文的人物是誰？","解釋這段經文的時間、地點","為什麼作者寫這段經文？","為什麼這段經文裡的人物說、或做了這些事？","這段經文的作者是誰？","這段經文描述了什麼事件？","這些經文的教導是什麼？"],
                    tipTitle: "指引",
                    oldTestaments: "舊約",
                    newTestaments: "新約",
                    searchTip: "請輸入關鍵字來搜索匹配的經文。",
                    searchResults1: "關鍵字共有",
                    searchResults2: "個結果。單擊經文結果即可轉到章節。",
                    searchOverResultsTip: "關鍵字有超過 500 個結果（僅限 500 個）。請換其他關鍵字搜索。單擊經文結果即可轉到章節。",
                    searchNoResult1: "關鍵字共有 0 條結果",
                    searchNoResult2: "請輸入關鍵字來搜索匹配的詩句。",
                    signIn: "登入",
                    signUp: "註冊",
                    signOut: "登出",
                    username: "用戶",
                    password: "密碼",
                    dontHaveAnAccount: "沒有帳號?",
                    contentComingSoon: "內容即將來臨...",
                    profile: "個人信息",
                    settings: "設置",
                    general: "一般",
                    helpAndSupport: "幫助支持",
                    helpAndSupportCopy: "幫助支持",
                    faq: "常見問題",
                    faqCopy: "常見問題",
                    aboutUs: "關於我們",
                    about: "關於",
                    aboutCopy: "<p>什麼是“Biblearn”？ 這聽起來像是一個古怪的混搭，它是“聖經/Bible”和“學習/Learn”的有趣融合，這是我嘗試讓閱讀經文變得有吸引力所寫的的應用。</p><p>為什麼“Biblearn”？身為一名軟件工程師、基督徒和聖經讀者，我經常發現自己渴望解開上帝話語中蘊含的真理。 我經常有疑問、反思，並渴望更深入地了解聖經的教導。 這促使我構想了這個平台，專為像我這樣尋求知識和理解的人而構建。 它能快速為您我提供答案，激發思考。</p><p>雖然 ChatGPT 的強大功能幫助用戶進行聖經探索方面帶來了價值，但運用 API 成本很高，為了維持服務，我請求發現該平台有益的用戶提供訂閱支持。 我理解每個人的需求都不同，因此我鼓勵您按自己認為合適的方式管理訂閱。</p><p>同樣重要的是要注意，雖然本工具力求準確性，但所提供的答案畢竟是由 ChatGPT AI 生成，因此可能不完全準確，敦促您僅使用這工具作為指南，而不是作為聖經知識的最終權威。 對於更複雜或更深入的問題，或您認為答案不對，建議您向牧師、神職人員或其他值得信賴的導師尋求幫助。</p><p>Biblearn 目前支持中文繁體和英文KJV聖經，其他語言的支持將陸續添加。</p><p>希望您發現這工具很有幫助並喜歡使用，讓我們一起踏上這段啟蒙之旅！</p>",
                    termsAndPrivacy: "條款和隱私",
                    termsOfService: "服務條款",
                    privacyPolicy: "隱私政策",
                    contactUs: "聯繫我們",
                    copyright: "版權所有",
                    pageNotFound: "找不到這頁面，返回"
                }
            };
