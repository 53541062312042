import React, { useState, useEffect, useRef } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import axios from 'axios';
import { l } from '../Languages';

const Read = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const token = JSON.parse(localStorage.getItem('jwtToken'));
    const { book, chapter, verse, end } = useParams();
    const [currentBook, setCurrentBook] = useState({});
    const navigate = useNavigate();
    const [data, setData] = useState(null);
    const [bookData, setBookData] = useState(null);
    const [chapterCount, setChapterCount] = useState(0);
    const [bookCount, setBookCount] = useState(0);
    const [jumpChapter, setJumpChapter] = useState(false);
    const [jumpBook, setJumpBook] = useState(false);
    const [fontSettings, setFontSettings] = useState(false);
    const [clickedIndices, setClickedIndices] = useState([]);
    const [clickedVerses, setClickedVerses] = useState({});
    const [isOpen, setIsOpen] = useState(false);
    const [animation, setAnimation] = useState('slide-in');
    const [selectedQuestion, setSelectedQuestion] = useState("");
    const [messages, setMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState("");
    const bottomRef = useRef();
    const [loading, setLoading] = useState(false);
    const [responseFailed, setResponseFailed] = useState("");
    const [animationStage, setAnimationStage] = useState(0);
    const loadingAnimationStages = ['   .   ', '  .  .  ', ' .   .  . '];
    const [startX, setStartX] = useState(0);
    let lsFontSize;
    if (localStorage.getItem('fontSize')) lsFontSize = JSON.parse(localStorage.getItem('fontSize'));
    else lsFontSize = 18;
    const [fontSize, setFontSize] = useState(lsFontSize);
    let lsFontWeight;
    if (localStorage.getItem('fontWeight')) lsFontWeight = JSON.parse(localStorage.getItem('fontWeight'));
    else lsFontWeight = 300;
    const [fontWeight, setFontWeight] = useState(lsFontWeight);
    let lsLineHeight;
    if (localStorage.getItem('lineHeight')) lsLineHeight = parseFloat(JSON.parse(localStorage.getItem('lineHeight')));
    else lsLineHeight = 1.50;
    const [lineHeight, setLineHeight] = useState(lsLineHeight);
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    useEffect(() => {
        if(!book) navigate('/read/1/1');
        setCurrentBook({ book, chapter, verse, end });
    }, []);

    useEffect(() => {
        fetchData();
    }, [navigate]); 

    useEffect(() => {
        setBookData(JSON.parse(localStorage.getItem('bookData')));
    }, []);

    useEffect(() => {
        if (bookData) {
            setChapterCount(bookData[book - 1].chapter_count);
            setBookCount(bookData.length);
        }
    }, [bookData]);

    useEffect(() => {
        if (bottomRef.current) {
            bottomRef.current.scrollIntoView({ behavior: "smooth" });
        }
    }, [messages]);

    useEffect(() => {
        if (!loading) return;
        const interval = setInterval(() => {
          setAnimationStage((prevStage) => (prevStage + 1) % loadingAnimationStages.length);
        }, 500);
        return () => clearInterval(interval);
    }, [loading]);

    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                console.log('Escape key was pressed');
                handleClose();
            }
            if (event.key === 'ArrowLeft') {
                prevChapter();
            }
            if (event.key === 'ArrowRight') {
                nextChapter();
            }
        };
        window.addEventListener('keydown', handleKeyDown);
        return () => {
            window.removeEventListener('keydown', handleKeyDown);
        };
    }, []);

    const fetchData = async (url) => {
        let fetchUrl;
        if (book && chapter && verse && end) {
            fetchUrl = `${API_URL}/book/${book}/${chapter}/${verse}/${end}`;
        } else if (book && chapter && verse && !end) {
            fetchUrl = `${API_URL}/book/${book}/${chapter}/${verse}`;
        } else if (book && chapter && !verse && !end) {
            fetchUrl = `${API_URL}/book/${book}/${chapter}`;
        } else if (book && !chapter && !verse && !end) {
            fetchUrl = `${API_URL}/book/${book}`;
        } else if (!book && !chapter && !verse && !end) {
            fetchUrl = `${API_URL}/book/1`;
        }
        console.log(`fetchData - ${fetchUrl}`);
        setData(null);
        axios.defaults.headers.bible = localStorage.getItem('selectedBible');
        axios.defaults.headers.language = localStorage.getItem('selectedLanguage');
        const response = await axios.get(fetchUrl);
        if (response.data.verses.length === 0) navigate('/read/1/1');
        setData(response.data.verses);
        if (bookData) setChapterCount(bookData[book - 1].chapter_count);
        setClickedVerses({});
        setClickedIndices([]);
        // console.log(`book: ${bookData[book - 1].title} chapter count: ${chapterCount}`);
    }

    const handleOpen = () => {
        setIsOpen(true);
        setAnimation('slide-in');
      }

    const handleClose = () => {
        setAnimation('slide-out');
        setTimeout(() => setIsOpen(false), 500); // match animation duration
      }

    const handleJumpChapter = () => {
        setJumpChapter(!jumpChapter);
        setJumpBook(false);
        setFontSettings(false);
    }

    const handleJumpBook = () => {
        setJumpBook(!jumpBook);
        setJumpChapter(false);
        setFontSettings(false);
    }

    const handleBookSelected = () => {
        setJumpChapter(true);
        setJumpBook(false);
        setFontSettings(false);        
    }

    const handleFonts = () => {
        setFontSettings(!fontSettings);
        setJumpBook(false);
        setJumpChapter(false);
    }

    const handleClick = (index) => {
        if (clickedIndices.includes(index)) {
            setClickedIndices(clickedIndices.filter(i => i !== index));
            delete clickedVerses[index];
            const tmp = getSelectedVerses();
            setInputMessage(tmp);
        } else {
            setClickedIndices([...clickedIndices, index]);
            const tmpData = { ...clickedVerses };
            tmpData[index] = data[index];
            tmpData[index].book = bookData[book - 1].title;
            setClickedVerses(tmpData);
            const tmp = getSelectedVerses();
            setInputMessage(tmp);
        }

        // console.log(clickedIndices);
        // console.log( Object.prototype.toString.call(clickedVerses));
        // console.log(JSON.stringify(clickedVerses));
    };

    const getSelectedVerses = () => {
        let string = "";
        Object.values(clickedVerses).forEach(value => {
            string = string + `${value.book} ${value.chapter_num + 1}:${value.verse_num + 1} ${value.content}`;
          });
        return string;
    }

    const contentStyle = {
        width: '100%',
        float: 'left',
        marginBottom: '10rem',
    }

    const cardNavStyle = {
        width: '100%',
        float: 'left',
        marginBottom: '20px',
    }

    // chat window begin

    const addMessage = (message, isUser) => {
        setMessages(prevMessages => [...prevMessages, { text: message, isUser }]);
        setInputMessage('');
    };

    const handleSend = () => {
        console.log(`handleSend`);
        if (inputMessage) {
            addMessage(inputMessage, true);
            const query = {
                verses: "",
                question: inputMessage,
                lang: localStorage.getItem('selectedLanguage')
            }
            console.log(JSON.stringify(query) + `${API_URL}/explainVerses`);
            const fetchAnswer = async () => {
                try {
                    setLoading(true);
                    // checkResponse();
                    setResponseFailed("");
                    const response = await axios.post(`${API_URL}/explainVerses`, query, { headers: {Authorization: token.accessToken, language: localStorage.getItem('selectedLanguage') }});
                    // handle response here
                    console.log(response.data);
                    console.log(JSON.stringify(response));
                    addMessage(response.data, false);
                    setLoading(false);
                } catch (error) {
                    setLoading(false);
                    setResponseFailed(`You need to have the privilege and sign in to use the chat.`);
                    console.error('An error occurred during signup', error,);
                    // handle error here
                    
                }
            }
            fetchAnswer();
        }
    };

    const checkResponse = () => {
        console.log(`checkResponse`);
        setTimeout(() => {
            console.log(`checkResponse times up ${loading}`);
            if (loading) {
                setLoading(false);
                setResponseFailed("The service timeout or you don't have privilege to use the service.");
            } 
        }, 10000);
    }

    const chapterActiveStyle = () => {
        return { color: '#ffffff', padding: '6px 3px 1px 0px', backgroundColor: '#b3adbe', borderRadius: '8px' };
    };

    const handleKeyDown = event => {
        if (event.key === 'Enter' && !event.shiftKey) {
            handleSend();
            event.preventDefault();
        }

        if (event.key === 'Escape') {
            handleClose();
            event.preventDefault();
            console.log('Escape');
        }

    };
    // chat window end

    const handleNavigate = (link) => {
        navigate(link);
    }

    const handleTouchStart = (e) => {
        setStartX(e.touches[0].clientX); // Get initial X position when touch starts
      }

      const handleTouchEnd = (e) => {
        const endX = e.changedTouches[0].clientX; // Get final X position when touch ends
        if (startX - endX > 120) { // If the swipe distance is larger than 100px
          nextChapter();
        }
        if (startX - endX < -120) { // If the swipe distance is less than -100px
          prevChapter();
        }
      }

    const prevChapter = () => {
        var prevBookMaxChapter;
        var prevBook;
        if (parseInt(chapter) === 1 && parseInt(book) === 1) {
            navigate(`/read/1/1`);
        } else if (parseInt(chapter) === 1 && book > 1) {
            prevBookMaxChapter = bookData[book - 2].chapter_count;
            prevBook = parseInt(book) - 1;
            navigate(`/read/${prevBook}/${prevBookMaxChapter}`);
        } else {
            if (!chapter) navigate(`/read/${book}/1`);
            if (parseInt(chapter) === 1) navigate(`/read/${book}/1`);
            if (chapter > 1) navigate(`/read/${book}/${parseInt(chapter) - 1}`);
        }
    }

    const nextChapter = () => {
        const curBookMaxChapter = bookData[book - 1].chapter_count;
        const nextChapter = parseInt(chapter) + 1;
        const nextBook = parseInt(book) + 1;
        if (nextChapter > curBookMaxChapter && parseInt(book) === 66) {
            navigate(`/read/1/1`);
        } else if (nextChapter > curBookMaxChapter && book < 66) {
            navigate(`/read/${nextBook}/1`);
        } else {
            if (!chapter) navigate(`/read/${book}/2`);
            if (parseInt(chapter) === 1) navigate(`/read/${book}/2`);
            if (chapter > 1) navigate(`/read/${book}/${parseInt(chapter) + 1}`);   
        }
    }

    const increaseFontSize = () => {
        if(fontSize < 30){
            setFontSize(fontSize + 2);
            localStorage.setItem('fontSize', fontSize + 2);
        }
    }

    const decreaseFontSize = () => {
        if(fontSize > 12){
            setFontSize(fontSize - 2);
            localStorage.setItem('fontSize', fontSize - 2);
        }
    }

    const increaseFontWeight = () => {
        if(fontWeight < 900) {
            let newFontWeight = fontWeight + 100;
            setFontWeight(newFontWeight);
            console.log(newFontWeight);
            // localStorage.removeItem('fontWeight');
            localStorage.setItem('fontWeight', newFontWeight);
        }
    }

    const decreaseFontWeight = () => {
        if(fontWeight > 100) {
            let newFontWeight = fontWeight - 100;
            setFontWeight(newFontWeight);
            console.log(newFontWeight);
            // localStorage.removeItem('fontWeight');
            localStorage.setItem('fontWeight', newFontWeight);
        }
    }

    const decreaseLineHeight = () => {
        if(lineHeight > 1.1){
            let newLineHeight = lineHeight - 0.10;
            // newLineHeight = newLineHeight.toFixed(2);
            setLineHeight(newLineHeight);
            localStorage.removeItem('lineHeight');
            setTimeout(() => {localStorage.setItem('lineHeight', newLineHeight.toFixed(2))}, 300);
        }
    }

    const increaseLineHeight = () => {
        if(lineHeight < 2.20){
            let newLineHeight = lineHeight + 0.1;
            // newLineHeight = newLineHeight.toFixed(2);

            setLineHeight(newLineHeight);
            localStorage.removeItem('lineHeight');
            setTimeout(() => {localStorage.setItem('lineHeight', newLineHeight.toFixed(2))}, 300);
        }
    }

    const resetFonts = () => {
        setFontSize(18);
        localStorage.setItem('fontSize', 18);
        setFontWeight(300);
        localStorage.setItem('fontWeight', 300);
        setLineHeight(1.5);
        localStorage.setItem('lineHeight', 1.5);
    }

    const handleQuestionDropdown = (e) => {
        setSelectedQuestion(e.target.value);
        let string = "";
        Object.values(clickedVerses).forEach(value => {
            string = string + `${value.book} ${value.chapter_num + 1}:${value.verse_num + 1} ${value.content}`;
        });
        setInputMessage(string + e.target.value);
    }

    return  <div className="main">

                <section className="page-title page-title-parallax parallax include-header py-2">
                    <div className="container">
                        <div className="row col-mb-10">
                            <div className="col-md-1"></div>
                            {/* ================= Read Title : Book Name : Chapter ================= */}
                            <div className="col-md-3">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{l[s].read}</h1>
                                        <h3>
                                            {bookData && bookData[book - 1] ? bookData[book - 1].title : ``}<br/>
                                            {chapter && s == 'enUs' ? `${l[s].chapter}` : ``} {chapter ? `${chapter}` : 1} {chapter && s == 'zhTw' ? `${l[s].chapter}` : ``}
                                            {verse ? `:${verse}${l[s].verseShort}` : ``} 
                                        </h3>
                                    </div>
                                </div>
                            </div>
                            {/* ================= Chapter Quick Jump ================= */}
                            <div className="col-md-7">
                                <div className="mw-sm mx-auto">
                                    <div className="row">
                                        <div className="col-8">
                                            <button className={ jumpBook ? "jump-chapter-button button button-mini button-border button-rounded button-leaf selected-jump-button" : "jump-chapter-button button button-mini button-border button-rounded button-leaf" } onClick={handleJumpBook}>{jumpBook ? <i className="fa fa-caret-down" style={{ left: `6px`, position: `absolute` }}></i> : <i className="fa fa-caret-right" style={{ left: `6px`, position: `absolute` }}></i>}&nbsp;&nbsp;&nbsp;{l[s].books}</button>
                                            <button className={ jumpChapter ? "jump-chapter-button button button-mini button-border button-rounded button-leaf selected-jump-button" : "jump-chapter-button button button-mini button-border button-rounded button-leaf" } onClick={handleJumpChapter}>{jumpChapter ? <i className="fa fa-caret-down" style={{ left: `6px`, position: `absolute` }}></i> : <i className="fa fa-caret-right" style={{ left: `6px`, position: `absolute` }}></i>}&nbsp;&nbsp;&nbsp;{l[s].chapter}</button>
                                            <button className={ fontSettings ? "jump-chapter-button button button-mini button-border button-rounded button-leaf selected-jump-button" : "jump-chapter-button button button-mini button-border button-rounded button-leaf" } onClick={handleFonts}>{fontSettings ? <i className="fa fa-caret-down" style={{ left: `6px`, position: `absolute` }}></i> : <i className="fa fa-caret-right" style={{ left: `6px`, position: `absolute` }}></i>}&nbsp;&nbsp;&nbsp;{l[s].fonts}</button>
                                        </div>
                                        <div className="col-4">
                                            <button onClick={prevChapter} className="button button-mini button-border button-rounded button-leaf prev-next-nav-button"><i className="fa fa-angles-left prev-next-nav-icon"></i></button>
                                            <button onClick={nextChapter} className="button button-mini button-border button-rounded button-leaf prev-next-nav-button"><i className="fa fa-angles-right prev-next-nav-icon"></i></button>
                                            
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-12">
                                            <div style={{ display: jumpBook ? 'inline-block' : 'none' }} >
                                                {Array.isArray(bookData) && bookData.map((item, index) => (
                                                    <Link key={index} className={ (index + 1) == book ? 'book-grid selected-chapter-grid' : 'book-grid' } to={`/read/${index + 1}/1`} onClick={handleBookSelected}>
                                                        <div>{item.short_title}</div>
                                                    </Link>
                                                ))}
                                            </div>
                                            <div style={{ display: jumpChapter ? 'inline-block' : 'none' }}>
                                                {Array.from({ length: chapterCount }, (_, index) => (
                                                    <Link key={index} className={ (index + 1) == chapter ? 'chapter-grid selected-chapter-grid' : 'chapter-grid' } to={`/read/${book}/${index + 1}`}><div >{index + 1}</div></Link>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="btn-toolbar" style={{ display: fontSettings ? 'inline-block' : 'none' }}>
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.9em`, padding: `0px 8px`, marginRight: `-4px`}} onClick={decreaseFontSize}>a</button>
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.9em`, padding: `0px 8px`, marginRight: `-4px`}} onClick={increaseFontSize}>A</button>
                                            &nbsp;&nbsp;
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.9em`, padding: `0px 8px`, marginRight: `-4px`}} onClick={decreaseFontWeight}>B</button>
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.9em`, padding: `0px 8px`, marginRight: `-4px`}} onClick={increaseFontWeight}><b>B</b></button>
                                            &nbsp;&nbsp;
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.7em`, padding: `0px 0px 0px 8px`, marginRight: `-4px`}} onClick={decreaseLineHeight}><i className="fa fa-down-left-and-up-right-to-center"></i></button>
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.7em`, padding: `0px 0px 0px 8px`}} onClick={increaseLineHeight}><i className="fa fa-up-right-and-down-left-from-center"></i></button>
                                            <button type="button" className="button button-mini button-border button-rounded button-leaf" style={{fontSize: `.7em`, padding: `0px 0px 0px 8px`, marginRight: `-4px`}} onClick={resetFonts}><i className="fa fa-arrow-rotate-left"></i></button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-1"></div>
                        </div>
                    </div>
                </section>

                {/*  ================= Main Content ================= */}
                <section id="content" onTouchStart={handleTouchStart} onTouchEnd={handleTouchEnd}>
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="single-post mw-sm mx-auto">
                                <div className="entry">
                                    <div className="entry-content" style={{fontSize: `${fontSize}px`, fontWeight: `${fontWeight}`}}>
                                        {Array.isArray(data) && data.map((item, index) => {
                                            const isClicked = clickedIndices.includes(index);
                                            return (
                                              <div key={index}>
                                                <p
                                                  onClick={() => handleClick(index)}
                                                  style={{ cursor: 'pointer', margin: '0.2em', borderRadius: '6px', display: 'inline-flex',
                                                           backgroundColor: isClicked ? '#efefef' : 'rgba(0,0,0,0)', padding: '4px 4px',
                                                           lineHeight: `${lineHeight}em`  }}
                                                >
                                                  <sup>{item.verse_num + 1}</sup> {item.content}
                                                </p>
                                              </div>
                                            );
                                          })}
                                        { !data && ("Loading...")}
                                    </div>
                                </div>
                            </div>
                            <button className="floating-button-left" onClick={prevChapter}><i className="fa fa-chevron-left floating-button-icons"></i></button>
                            <button className="floating-button-right" onClick={nextChapter}><i className="fa fa-chevron-right floating-button-icons"></i></button>
                        </div>
                    </div>
                </section>

                
                {!isOpen && (<button onClick={handleOpen} className='floating-ask-button button button-3d button-rounded gradient-horizon'>
                    <i className="fa fa-lightbulb"></i></button>)}

                  {isOpen && (
                    <div className={`overlay ${animation}`}>
                        <div className="container">
                            <div className="row col-mb-50">
                                <div className="col-md-12">
                                    <div className='cardTitle'>
                                        <div className="row">
                                            <div className="col-4">
                                                <button onClick={handleClose} className="btn btn-primary"> X </button>
                                            </div>
                                            <div className="col-8">
                                                <h2><i className="fa fa-lightbulb"></i> {l[s].ask}</h2>
                                            </div>
                                        </div>
                                        
                                        
                                        <div className="chat-container">
                                            <div className="chat-message-list">
                                                {messages.length === 0 && (
                                                    <div className='chat-tips'>
                                                        <h4><i className="fa fa-circle-info"></i> {l[s].tipTitle}</h4>
                                                        <ol>
                                                            {Array.isArray(l[s].chatTips) && l[s].chatTips.map((item, index) => (
                                                                <li key={index}>
                                                                    {item}
                                                                </li>
                                                            ))}
                                                        </ol>
                                                    </div>)}
                                                {messages.map((message, index) => (
                                                <div key={index} className={message.isUser ? 'chat-message user' : 'chat-message'}>
                                                    <div>{message.text}</div>
                                                </div>
                                                ))}
                                                {loading && 
                                                    <div className="chat-message chat-loading">
                                                      {loadingAnimationStages[animationStage]}
                                                    </div>
                                                }
                                                {responseFailed != "" &&
                                                    <div className="chat-message">
                                                      {responseFailed}
                                                    </div>
                                                }
                                                <div ref={bottomRef} />
                                                    
                                                </div>
                                            <div className="form-group">
                                                { Object.keys(clickedVerses).length > 0 && (<select value={selectedQuestion} onChange={handleQuestionDropdown} className="form-select">
                                                    {Array.isArray(l[s].chatQuestions) && l[s].chatQuestions.map((item, index) => (
                                                        <option key={index} value={item}>
                                                            {item}
                                                        </option>
                                                    ))}
                                                </select>)}
                                            </div>
                                            <div className="chat-input">
                                                <textarea 
                                                    value={inputMessage} 
                                                    onChange={e => setInputMessage(e.target.value)} 
                                                    onKeyDown={handleKeyDown}
                                                    rows="8"
                                                    style={{ width: '90%', height: 'auto', overflowY: 'hidden' }}
                                                    className="form-control"
                                                />
                                                <button onClick={handleSend} className="btn btn-primary"><i className="fa fa-paper-plane"></i></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>


                    
                    </div>
                  )}
                
            </div>;
};

export default Read;