import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { l } from '../Languages';

const Bookmarks = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [isValidToken, setIsValidToken] = useState(false);
    const [token, setToken] = useState(null);
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    useEffect(() => {
        const fetchToken = () => {
            setToken(JSON.parse(localStorage.getItem('jwtToken')));
        }
        fetchToken();
    }, []);

    useEffect(() => {
        if (!isValidToken) navigate('/signin');
    }, [isValidToken]);

    const checkToken = () => {
        if (token) {
            console.log(token);
            
            fetch(`${API_URL}/verifyToken`, {
                method: 'GET',
                headers: {
                    Authorization: `${token.accessToken}`
                }
            })
            .then(response => {
                if (response.status === 200) {
                    setIsValidToken(true);
                    setToken(JSON.parse(localStorage.getItem('jwtToken')));
                } else {
                    setIsValidToken(false);
                    localStorage.removeItem('jwtToken');
                    setToken(null);
                    navigate('/signin');
                }
            })
            .catch(error => {
                console.error('Error:', error);
                setIsValidToken(false);
                localStorage.removeItem('jwtToken');
                setToken(null);
                navigate('/signin');
            });
        } else {
            navigate('/signin');
        }
    };

    checkToken();

    if (!isValidToken) {
        return <div>Loading...</div>;
    }

    // Render the restricted content if the token is valid
    return (
        <div>
            <h1>Bookmarks</h1>
        </div>
    );
};

export default Bookmarks;


