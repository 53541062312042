import { Link, useNavigate } from 'react-router-dom';
import { l } from '../Languages';

const NoPage = () => {
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    return  <div className="pure-pg">
                <div className="pure-u-1-5"></div>
                <div className="pure-u-3-5">
                    <h1>Oops...</h1>
                    <br></br>
                    <br></br>
                    <p>{l[s].pageNotFound} <Link to="/"><i className="fa fa-home"></i> {l[s].home}</Link></p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                    <br></br>
                </div>
                <div className="pure-u-1-5"></div>
            </div>;
};

export default NoPage;