import React, { useEffect, useState } from 'react';
import { Outlet, Link, useNavigate, useLocation } from "react-router-dom";
import axios from 'axios';
import { l } from '../Languages';

const Layout = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const location = useLocation();
    const pathname = location.pathname;
    const path = pathname.split('/')[1];
    const [token, setToken] = useState(null);
    const [user, setUser] = useState(null);
    const [primaryMenuTriggerActive, setPrimaryMenuTriggerActive] = useState(false);
    const [primaryMenuActive, setPrimaryMenuActive] = useState(false);
    const [menuContainerActive, setMenuContainerActive] = useState(false);
    const [windowDimensions, setWindowDimensions] = useState({ width: window.innerWidth, height: window.innerHeight });
    const [hamburgerMenuDisplay, sethamburgerMenuDisplay] = useState(false);
    const [lang, setSelectedLanguage] = useState("enUs");
    const [selectedBible, setSelectedBible] = useState("enUsKjv");
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    const handleSignOut = async () => {
        localStorage.removeItem('jwtToken');
        setToken(null);
        localStorage.removeItem('user');
        setUser(null);
        try {
            await axios.delete(`${API_URL}/logout`, {
                headers: { 'Authorization': token.accessToken }
            });
        } catch (error) {
            console.error('An error occurred during logout', error);
        }
        navigate('/signin');
    };

    const handleMenu = async () => {
        setPrimaryMenuTriggerActive(!primaryMenuTriggerActive);
        setPrimaryMenuActive(!primaryMenuActive);
        setMenuContainerActive(!menuContainerActive);
    }

    const handleCloseMobileMenu = async () => {
        setPrimaryMenuTriggerActive(false);
        setPrimaryMenuActive(false);
        setMenuContainerActive(false);
    }

    const fetchData = async () => {
        console.log(localStorage.getItem('selectedBible'));
        let headerBible = localStorage.getItem('selectedBible');
        let headerLanguage = localStorage.getItem('selectedLanguage');
        if (headerBible === null) headerBible = "enUsKjv";
        if (headerLanguage === null) headerLanguage = "enUs";
        axios.defaults.headers.bible = headerBible;
        axios.defaults.headers.language = headerLanguage;
        const response = await axios.get(`${API_URL}/books`);
        console.log(response);
        localStorage.setItem('bookData', JSON.stringify(response.data.books));
    }

    useEffect(() => {

        if(localStorage.getItem('selectedBible')) {
            setSelectedBible(localStorage.getItem('selectedBible'));
        } else {
            localStorage.setItem('selectedBible', selectedBible);
        }

        if(localStorage.getItem('selectedLanguage')) {
            setSelectedLanguage(localStorage.getItem('selectedLanguage'));
        } else {
            localStorage.setItem('selectedLanguage', lang);
        }

        if(!localStorage.getItem('languageSet')) {
            localStorage.setItem('languageSet', JSON.stringify(l));
        }

        fetchData(); // calling the function
    }, [API_URL]); // passing an empty dependency array to make it run only after the first render

    useEffect(() => {
        const fetchToken = () => {
            setToken(JSON.parse(localStorage.getItem('jwtToken')));
            // console.log(token);
        }
        fetchToken();
    }, []);

    useEffect(() => {
        const fetchUser = (userData) => {
            setUser(JSON.parse(localStorage.getItem('user')));
        }
        fetchUser();
    }, []);

    useEffect(() => {
        function handleResize() {
            setWindowDimensions({
                width: window.innerWidth,
                height: window.innerHeight
            });
            if (windowDimensions.width > 990) {
                handleCloseMobileMenu();
            }
        }
        
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div id="wrapper">
            {/* ======================== Header ======================== */}
            <header id="header" className="header-size-sm" data-sticky-shrink="false">
                <div id="header-wrap" className="border-bottom-0">
                    <div className="container">
                        <div className="header-row justify-content-lg-between">

                            {/* ======================== Logo ======================== */}
                            <div id="logo" className="me-lg-0">
                                <Link to="/">Biblearn</Link>
                            </div>

                            <div className="header-misc">

                                {/* ======================== Top Cart ========================
                                <div id="top-cart" className="header-misc-icon me-3">
                                    <Link to="#" id="top-cart-trigger"><i className="bi-basket3"></i></Link>
                                    <div className="top-cart-content">
                                        <div className="top-cart-title">
                                            <h4 className="fw-normal">Shopping Cart</h4>
                                        </div>
                                        <div className="top-cart-items py-4">
                                            <p>Ohh! Your Basket is empty.</p>
                                        </div>
                                        <div className="top-cart-action">
                                            <Link to="#" className="button button-small bg-color color bg-opacity-10 m-0">Go to Shop</Link>
                                            <span className="top-checkout-price">$0.00</span>
                                        </div>
                                    </div>
                                </div>
                                */}

                               {/* ======================== Top Account ======================== */}
                                <div className="header-misc-icon top-account">
                                    <Link to="/signin" data-bs-toggle="dropdown" data-bs-offset="0,20" data-bs-auto-close="true" aria-haspopup="true" aria-expanded="false"><i className="bi-person-circle"></i></Link>
                                    <div className="dropdown-menu dropdown-menu-end px-2 m-0">
                                        <Link className="dropdown-item py-2 fw-medium h-bg-tranparent font-primary" href="#"><i className="bi-person me-2"></i>Your Profile</Link>
                                        <Link className="dropdown-item py-2 fw-medium h-bg-tranparent font-primary" href="#"><i className="bi-box-arrow-right me-2"></i>Log Out</Link>
                                    </div>
                                </div>
                            </div>


                            <div className={`${primaryMenuTriggerActive ? "primary-menu-trigger primary-menu-trigger-active" : "primary-menu-trigger"}`} style={{ display: hamburgerMenuDisplay ? 1 : 0 }} onClick={handleMenu}>
                                <button className="cnvs-hamburger" type="button" title="Open Mobile Menu">
                                    <span className="cnvs-hamburger-box"><span className="cnvs-hamburger-inner"></span></span>
                                </button>
                            </div>

                            <nav className={`${primaryMenuActive && windowDimensions.width < 990 ? "primary-menu primary-menu-active" : "primary-menu"}`}>
                                <ul className={`${menuContainerActive && windowDimensions.width < 990 ? "menu-container d-block" : "menu-container"}`}>
                                    <li className={`${path === "read" ? "menu-item current" : "menu-item" }`} onClick={handleMenu}><Link to="/read/1/1" className="menu-link"><i className="fa fa-glasses"></i> {l[s].read}</Link></li>
                                    <li className={`${path === "books" ? "menu-item current" : "menu-item" }`} onClick={handleMenu}><Link to="/books" className="menu-link"><i className="fa fa-book"></i> {l[s].books}</Link></li>
                                    <li className={`${path === "search" ? "menu-item current" : "menu-item" }`} onClick={handleMenu}><Link to="/search" className="menu-link" onClick={handleMenu}><i className="fa fa-search"></i> {l[s].search}</Link></li>
                                </ul>
                            </nav>

                        </div>
                    </div>
                </div>
                <div className="header-wrap-clone"></div>
            </header>


                    {/*localStorage.getItem('jwtToken') === null ? 
                        <div className="nav-right">
                            <Link to="/signin"><i className="fa fa-user"></i> Sign In</Link>
                        </div> : 
                        <div className="nav-right">
                            <div className="pure-menu pure-menu-horizontal">
                                <ul className="pure-menu-list">
                                    <li className="pure-menu-item pure-menu-has-children pure-menu-allow-hover">
                                        <i className="fa fa-user"></i> {user !== null ? user.username : null}
                                        <ul className="pure-menu-children">
                                            <li className="pure-menu-item">
                                                <Link className="pure-menu-link">Profile</Link>
                                            </li>
                                            <li className="pure-menu-item" onClick={handleSignOut}>
                                                <Link className="pure-menu-link">Sign out</Link>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    */}

            <Outlet />

            

            <footer className="bg-white border-0">
                <div className="container">
                    <div className="footer-widgets-wrap">
                        {/*<div className="row justify-content-center text-center col-mb-50">
                                                    <div className="col-lg-5 col-md-10">
                                                        <h2>Follow Us if you want to know how magical <span>sound</span> really is.</h2>
                                                        <div className="d-flex justify-content-center">
                                                            <Link to="#" className="social-icon si-small text-light bg-color bg-opacity-10 color h-bg-facebook border-0">
                                                                <i className="fa-brands fa-facebook-f"></i>
                                                                <i className="fa-brands fa-facebook-f"></i>
                                                            </Link>
                                                        </div>
                                                    </div>
                                                </div>*/
                        <div className="clear line border-color op-03 border-width-2"></div>}
                        <div className="row col-mb-50">
                            <div className="col-lg-3 col-6">
                                <div className="widget widget_links">
                                    <h4 className="text-transform-none ls-0 fw-medium fs-5 font-body">{l[lang].general}</h4>
                                    <ul>
                                        <li><Link to="/support">{l[lang].helpAndSupport}</Link></li>
                                        <li><Link to="/faq">{l[lang].faq}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="widget widget_links">
                                    <h4 className="text-transform-none ls-0 fw-medium fs-5 font-body">{l[lang].aboutUs}</h4>
                                    <ul>
                                        <li><Link to="/about">{l[lang].about}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-6">
                                <div className="widget widget_links">
                                    <h4 className="text-transform-none ls-0 fw-medium fs-5 font-body">{l[lang].termsAndPrivacy}</h4>
                                    <ul>
                                        <li><Link to="/terms">{l[lang].termsOfService}</Link></li>
                                        <li><Link to="/privacy">{l[lang].privacyPolicy}</Link></li>
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-3 col-sm-6">
                                <div className="widget">
                                    <h4 className="text-transform-none ls-0 fw-medium fs-5 font-body">{l[lang].contactUs}</h4>
                                    <ul className="list-unstyled">
                                        <li className="mb-2"><Link className="text-dark" href="#"><i className="me-3 color bi-envelope-fill"></i>support@biblearn.com</Link></li>

                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="row justify-content-center mt-5">
                            <div className="col-auto">
                                <div className="card border-color op-04">
                                    <div className="card-body py-2 px-3">
                                        <small className=""><Link to="/">&copy; { new Date().getFullYear() } {l[lang].copyright} Biblearn.</Link></small>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </div>
    )
};

export default Layout;