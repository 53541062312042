import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { l } from '../Languages';

const SignUp = () => {
    const API_URL = process.env.REACT_APP_API_URL;
    const navigate = useNavigate();
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [cellnumber, setCellnumber] = useState("");
    const [email, setEmail] = useState("");
    const [error, setError] = useState("");
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    const handleSubmit = async (event) => {
        event.preventDefault();

        const user = {
          username,
          password,
          cellnumber,
          email
        };

        try {
            const response = await axios.post(`${API_URL}/register`, user);
            // handle response here
            console.log(response.data);
            localStorage.setItem('jwtToken', JSON.stringify(response.data));
            navigate('/');
        } catch (error) {
            console.error('An error occurred during signup', error);
            if (error.response.status === 400) {
                setError(error.response.data);
            }
        }
    };

    return  <div className="main">
                <section className="page-title page-title-parallax parallax include-header py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-4"></div>
                            <div className="col-md-4">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>Sign Up</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-4"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="row col-mb-50">
                                <div className="col-md-4"></div>
                                <div className="col-md-4">
                                    <div className="form-group">
                                        <form className="" onSubmit={handleSubmit}>
                                            <fieldset>
                                                <label id="username">Username *</label>
                                                <input type="text" value={username} className="form-control" onChange={e => setUsername(e.target.value)} placeholder="Username" required />
                                                <label id="email">Email *</label>
                                                <input type="email" value={email} className="form-control" onChange={e => setEmail(e.target.value)}  placeholder="Email" required />
                                                <label id="Password">Password *</label>
                                                <input type="password" value={password} className="form-control" onChange={e => setPassword(e.target.value)} placeholder="Password" required />
                                                <button type="submit" className="btn btn-primary mt-3">Sign Up</button>
                                                * required
                                            </fieldset>
                                        </form>
                                        {(<p style={{ color: '#FF0000', fontWeight: 500}}>{error}</p>)}
                                        Don't have an account? Contact Us.
                                    </div>
                                </div>
                                <div className="col-md-4"></div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>;
};

export default SignUp;