import { Link, useNavigate } from 'react-router-dom';
import VideoPlayer from '../VideoPlayer';
import { l } from '../Languages';

const Home = () => {
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    return    <div className="main">
                <section id="slider" className="slider-element py-5 mt-lg-5">
                    <div className="container">
                        <div className="row flex-sm-row-reverse justify-content-center justify-content-lg-between col-mb-80">
                            <div className="col-lg-6 col-8">
                                <div className="dotted-bg">
                                    <img src="/images/chat-tutorial.gif" alt="Home Tutorial" className="d-flex z-1 ms-auto w-100 position-relative home-gif" style={{ borderRadius: '2rem' }} />
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <h3 className="color fs-5 mb-3">{l[s].slogan1}</h3>
                                <img src="/images/biblearn-logo-animation.gif" />
                                <div className="mb-5 text-large font-primary me-3" dangerouslySetInnerHTML={{ __html: l[s].homeCopy }}></div>
                                <div className="d-flex align-items-center mb-6">
                                    <Link to="/read/1/1" className="button button-xlarge bg-opacity-10 bg-color color ms-0 px-5 px-md-6 py-3 m-0">{l[s].readNow}</Link>
                                    {/*<small className="ms-4 op-06 font-primary">Starting at $99 only.</small>*/}
                                </div>

                            </div>
                        </div>
                    </div>
                </section>
            </div>;
};

export default Home;



