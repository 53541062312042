import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { l } from '../Languages';

const Privacy = () => {
    const navigate = useNavigate();
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    return  <div className="main">

                <section className="page-title page-title-parallax parallax include-header py-3">
                    <div className="container">
                        <div className="row col-mb-50">
                            <div className="col-md-2"></div>
                            {/* ================= Privacy Policy ================= */}
                            <div className="col-md-8">
                                <div className="page-title-row mw-sm mx-auto">
                                    <div className="page-title-content">
                                        <h1>{l[s].privacyPolicy}</h1>
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-2"></div>
                        </div>
                    </div>
                </section>

                <section id="content">
                    <div className="content-wrap pt-0">
                        <div className="container">
                            <div className="single-post mw-sm mx-auto">
                                <div className="entry">
                                    <div className="entry-content">
                                        <p>Effective date: 6/26/2023</p>

                                        <p>This privacy policy describes how Biblearn ("we", "our", "us") collects, uses, and protects your personal information when you use our website and services.</p>
                                        
                                        <h4>What information we collect</h4>
                                        
                                        <p>We may collect personal information that you voluntarily provide to us when registering or interacting with our services. The personal information that we collect depends on the context of your interactions with us and the Services, the choices you make and the products and features you use. The personal information we collect can include the following:</p>

                                        <ul>
                                            <li>Name and Contact Data.</li>
                                            <li>Credentials.</li>
                                            <li>Payment Data.</li>
                                            <li>Device and Usage Data.</li>
                                        </ul>
                                        
                                        <h4>How we use your information</h4>
                                        
                                        <p>We use personal information collected via our Services for a variety of business purposes described below:</p>
                                        
                                        <ul>
                                            <li>To facilitate account creation and logon process.</li>
                                            <li>To send you marketing and promotional communications.</li>
                                            <li>To send administrative information to you.</li>
                                            <li>To fulfill and manage your orders or subscription.</li>
                                        </ul>
                                        
                                        <h4>Sharing Your Personal Information</h4>
                                        
                                        <p>We may share your personal information with third parties, as described in this privacy policy.</p>
                                        
                                        <h4>Your Privacy Rights</h4>
                                        
                                        <p>In accordance with the applicable laws, you have certain rights in relation to your personal information:</p>
                                        
                                        <ul>
                                            <li>Right to access: You have the right to request copies of your personal information.</li>
                                            <li>Right to rectification: You have the right to request that we correct any information you believe is inaccurate.</li>
                                            <li>Right to erasure: You have the right to request that we erase your personal information, under certain conditions.</li>
                                            <li>Right to restrict processing: You have the right to request that we restrict the processing of your personal information, under certain conditions.</li>
                                            <li>Right to object to processing: You have the right to object to our processing of your personal information, under certain conditions.</li>
                                            <li>Right to data portability: You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                                        </ul>
                                        
                                        <h4>CCPA Privacy Rights (Do Not Sell My Personal Information)</h4>
                                        
                                        <p>Under the CCPA, among other rights, California consumers have the right to:</p>
                                                                
                                        <ul>
                                            <li>Request that a business that collects a consumer's personal data disclose the categories and specific pieces of personal data that a business has collected about consumers.</li>
                                            <li>Request that a business delete any personal data about the consumer that a business has collected.</li>
                                            <li>Request that a business that sells a consumer's personal data, not sell the consumer's personal data.</li>
                                        </ul>
                                        
                                        <h4>GDPR Data Protection Rights</h4>
                                        
                                        <p>We would like to make sure you are fully aware of all of your data protection rights. Every user is entitled to the following:</p>
                                        
                                        <ul>
                                            <li>The right to access – You have the right to request copies of your personal data.</li>
                                            <li>The right to rectification – You have the right to request that we correct any information you believe is inaccurate.</li>
                                            <li>The right to erasure – You have the right to request that we erase your personal data, under certain conditions.</li>
                                            <li>The right to restrict processing – You have the right to request that we restrict the processing of your personal data, under certain conditions.</li>
                                            <li>The right to object to processing – You have the right to object to our processing of your personal data, under certain conditions.</li>
                                            <li>The right to data portability – You have the right to request that we transfer the data that we have collected to another organization, or directly to you, under certain conditions.</li>
                                        </ul>
                                        
                                        <h4>Changes to our Privacy Policy</h4>
                                        
                                        <p>We may change this privacy policy from time to time. We will post any privacy policy changes on this page and, if the changes are significant, we will provide a more prominent notice.</p>
                                        
                                        <h4>How to contact us</h4>
                                        
                                        <p>If you have any questions or concerns about our privacy policy, please <Link to="/Contact">contact us</Link> or email support@biblearn.com.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </div>;
};

export default Privacy;