import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
// import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./pages/Layout";
import Home from "./pages/Home";
import Read from "./pages/Read";
import Books from "./pages/Books";
import Search from "./pages/Search";
import Ask from "./pages/Ask";
import Bookmarks from "./pages/Bookmarks";
import Contact from "./pages/Contact";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import NoPage from "./pages/NoPage";
import About from "./pages/About";
import Privacy from "./pages/Privacy";
import Terms from "./pages/Terms";
import Support from "./pages/Support";
import Faq from "./pages/Faq";
import ScrollToTop from './ScrollToTop';
import './style.scss';

export default function App() {
    return (
        <BrowserRouter>
            <ScrollToTop />
            <Routes>
                <Route path="/" element={<Layout />}>
                    <Route index element={<Home />} />
                    <Route path="books" element={<Books />} />
                    <Route path="read/:book/:chapter/:verse/:end" element={<Read />} />
                    <Route path="read/:book/:chapter/:verse" element={<Read />} />
                    <Route path="read/:book/:chapter" element={<Read />} />
                    <Route path="read/:book" element={<Read />} />
                    <Route path="read" element={<Read />} />
                    <Route path="search" element={<Search />} />
                    <Route path="ask" element={<Ask />} />
                    <Route path="bookmarks" element={<Bookmarks />} />
                    <Route path="contact" element={<Contact />} />
                    <Route path="signin" element={<SignIn />} />
                    <Route path="signup" element={<SignUp />} />
                    <Route path="about" element={<About />} />
                    <Route path="privacy" element={<Privacy />} />
                    <Route path="terms" element={<Terms />} />
                    <Route path="support" element={<Support />} />
                    <Route path="faq" element={<Faq />} />
                    <Route path="*" element={<NoPage />} />
                </Route>
            </Routes>
        </BrowserRouter>
    );
}

const wrapper = ReactDOM.createRoot(document.getElementById('wrapper'));
wrapper.render(<App />);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
