import { l } from '../Languages';

const Contact = () => {
    let s;
    if (localStorage.getItem('selectedLanguage')) s = localStorage.getItem('selectedLanguage');
    else s = "enUs";

    return  <div className="main">
                <div className="pure-g">
                    <div className="pure-u-2-24"></div>
                    <div className="pure-u-20-24">
                        <h1>{l[s].contactUs}</h1>
                        <p>{l[s].contentComingSoon}</p>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                        <br></br>
                    </div>
                    <div className="pure-u-2-24"></div>
                </div>
            </div>;
};

export default Contact;